* {
  font-family: 'Fira Sans', sans-serif;
  font-weight: bold;
}

@media screen and (max-width: 499px) {

  .form {
    margin-left: auto;
    margin-right: auto;

  }

  .block_container {
    justify-content: center;
  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 20px;
    width: 25%;
    border: none;
    border-radius: 10px;
    padding: 7px;
  }

  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .button:hover {
    transform: scale(1.1);
  }

  .userPic {
    width: 35px;
    height: 35px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;  
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    padding-right: 70px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 20px;
    justify-content: flex-end;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-color: black;

  }

  .allWrap {
    background-size: auto;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;

  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: column wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: block;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: center;
    width: 600px;

  }

  .nftPic {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .writer {
    width: 25px;
    height: 25px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 90px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 50px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
    text-align: center;

  }

  .logo {
    width: 200px;
    height: 118px;
    margin-left: auto;
    margin-right: auto;
  }

  .logoDiv {

    justify-content: center;
    display: flex;
    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 40px;
    margin-top: 10px;
    justify-content: center;
    font-family: 'Bangers', cursive;
    letter-spacing: 5px;
    -webkit-text-stroke: 0.75px #2a4d87;
    -webkit-text-fill-color: white;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .headerPicAndTitle {
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: rgba(255, 187, 0, 0.301);
    border-width: 5px;
    border-style: solid;
    padding-bottom: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;

  }

  .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .form {
    margin-left: auto;
    margin-right: auto;

  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 20px;
    width: 25%;
    border: none;
    border-radius: 10px;
    padding: 7px;
  }

  .comment {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .block_container {
    justify-content: center;
  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .button:hover {
    transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;

  }

  .user {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    padding-right: 70px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 20px;
    justify-content: flex-end;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-color: black;

  }

  .allWrap {
    background-size: auto;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;

  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 270px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 85px;
    height: 85px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 25px;
    height: 25px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 90px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 50px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
    text-align: center;
  }

  .logo {
    width: 200px;
    height: 118px;
    margin-left: auto;
    margin-right: auto;
  }

  .logoDiv {

    justify-content: center;
    display: flex;
    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 60px;
    margin-top: 10px;
    justify-content: center;
    font-family: 'Bangers', cursive;
    letter-spacing: 5px;
    -webkit-text-stroke: 0.75px #2a4d87;
    -webkit-text-fill-color: white;
    margin-left: auto;
    margin-right: auto;

  }

  .headerPicAndTitle {
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: rgba(255, 187, 0, 0.301);
    border-width: 5px;
    border-style: solid;
    padding-bottom: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;

  }

  .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

@media screen and (min-width: 768px) {

  .form {
    margin-left: auto;
    margin-right: auto;

  }

  .main {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 2%;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1000;
    bottom: 0;
    background-color: black;
  }

  .form__input {
    font-size: 25px;
    width: 30%;
    border: none;
    border-radius: 10px;
    padding: 10px;
  }

  .comment {
    font-size: 20px;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;

  }

  .button {
    background-color: rgb(53, 173, 53);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform .2s;
    cursor: pointer;
    margin-top: 10px;
  }

  .button:hover {
    transform: scale(1.1);
  }

  .userPic {
    width: 45px;
    height: 45px;
    padding-right: 10px;
  }

  .user {
    display: flex;
    flex-flow: row nowrap;
  }

  .line {
    width: 100%;
    height: 5px;
    background-color: rgb(255, 196, 0);
    margin-bottom: 2%;
  }

  .tab1 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: black;

  }

  .centerMM {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;    
  }

  .tab1:hover {
    background-color: rgba(151, 151, 151, 0.637);

  }

  .wallet2 {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 70px;
    padding-right: 70px;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(250, 235, 215, 0);
    color: white;
    font-size: 20px;
    justify-content: flex-end;
  }

  .mM {
    width: 250px;
    height: 41px;
    cursor: pointer;
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-color: black;

  }

  .allWrap {
    background-size: auto;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;

  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .clientZone {
    flex-flow: row wrap;
    margin-left: 2%;
    padding-top: 2%;

  }

  .clientZoneMain {
    display: flex;
    margin-bottom: 5%;
    padding-top: 2%;
    justify-content: space-between;
    width: 600px;

  }

  .nftPic {
    width: fit-content;
  }

  .clientStory {
    font-size: 12px;
    color: black;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 400px;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 10px;
    text-align: justify;

  }

  .nftImg {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .picAndStory {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1%;
  }

  .writer {
    width: 30px;
    height: 30px;
  }

  .picAndName {
    display: flex;
    flex-flow: row nowrap;
    background-color: rgb(255, 187, 0);
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100px;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .writerDiv {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5PX;
  }

  .clientName {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    color: white;

  }

  .clientName2 {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 60px;
    color: white;

  }

  .ownersSection {
    display: flex;
    flex-flow: row wrap;
    overflow-x: scroll;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .voteArea {
    margin-top: 10px;
  }

  .rabitIcon {
    width: 80px;
    height: 80px;
    cursor: pointer
  }

  .rabitIcon:active {
    transform: translateY(4px);
  }

  .rabitIconDiv {

    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;
  }

  .submitted {

    text-align: center;
    font-size: large;
    color: white;

  }

  .rabitIconDiv button {


    background-color: rgba(240, 248, 255, 0);

  }

  .rabitIconDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .score {
    font-size: 60px;
  }

  .voteBtn {
    font-size: 25px;
    width: 50px;
    background-color: white;
    cursor: pointer;
    color: black;
    border-radius: 5px;
  }

  .voteBtn:hover {
    background-color: rgb(192, 192, 192);
    color: #5c5c5c;
  }

  .voteBtn:active {
    background-color: #363636;
    transform: translateY(4px);
    color: rgb(255, 255, 255);
  }

  .points {
    color: white;
    text-align: center;
    margin-top: 10px;
    font-size: 25px;
    background-color: #3b3b3b80;
  }

  .countWords {
    color: white;
  }

  .logo {
    width: 250px;
    height: 147px;
  }

  .logoDiv {

    margin-top: 10px;
  }

  .nb {
    color: white;
    font-size: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-family: 'Bangers', cursive;
    letter-spacing: 5px;
    -webkit-text-stroke: 0.75px #2a4d87;
    -webkit-text-fill-color: white;

  }

  .headerPicAndTitle {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: rgba(255, 187, 0, 0.301);
    border-width: 5px;
    border-style: solid;
    padding-bottom: 20px;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 13%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 35px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
    cursor: pointer;
  }

  .checkedPicDiv {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .checkedPic {
    width: 170px;
    height: 170px;
  }

  .loginFormNote {
    height: 300px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .noteSuccess {
    color: white;
    text-align: center;
    margin-top: 15%;
    font-size: 25px;
  }

  .noteSuccess2 {
    font-size: 15px;
    color: white;
    text-align: center;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
  }

  .voteBtn {
    color: white;
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn:hover {
    color: rgba(0, 0, 0, 0.562);
    font-size: 20px;
    width: 100%;
    border-style: outset;
    border-color: #363636;
    border-radius: 0;
    cursor: pointer;
  }

  .voteBtn2 {
    color: rgba(255, 255, 255, 0.562);
    font-size: 20px;
    text-align: center;

  }

  .voteBtn2:hover {
    color: rgba(179, 179, 179, 0.562);
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .continue {
    background-color: rgba(53, 173, 53, 0);
    border-radius: 7px;
    font-size: 20px;
    color: white;
    border: none;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .continue:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(88, 87, 82);
  border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(105, 105, 103);
  border-radius: 50px;
}